.HomeServicesMain{
    width: 100%;
    position: relative;
    padding: 30px 0;
}

.HomeServiceShapeBg{
    width: 85%;
    position: absolute;
    right: 0;
    height: 100%;
    background-color: #e6f8ee;
    border-radius: 10px 10px 250px 10px;
}

.ServicTest{
    width: 400px;
    height: 250px;
}

.HomeServiceUpperRow{
    padding: 0 5%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
}

.HomeServiceLowerRow{
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0 5%;
}

.HomeServicesTextDetails{
    margin-left: 50px;
    padding-top: 5px;
}

.HomeServicesTextDetailsHead{
    font-size: 20px;
    font-weight: 700;
    line-height: 25.2px;
    margin-bottom: 17px;
    color: #03c70e;
}

.HomeServicesTextDetailsMagaHead{
    font-size: 40px;
    font-weight: 700;
}

.HomeServicesTextDetailsHead4You{
    font-size: 40px;
    font-weight: 300;
}

.HomeServicesTextDetailsActionHolder{
    margin-top: 5px;
}

.HomeServicesTextDetailsAction{
    border-radius: 0 20px 20px 20px;
    height: 45px;
    width: 160px;
}

@media screen and (max-width: 600px) {
    .HomeServiceUpperRow{
        width: 100%;
        padding: 0 2%;
        flex-wrap: wrap;
        justify-content: center;
    }
    .HomeServiceLowerRow{
        width: 100%;
        padding: 0 2%;
        flex-wrap: wrap;
        justify-content: center;
    }
}