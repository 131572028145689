.popupAnyPageHolder{
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.popupCloseButton{
  position: absolute;
  left: 10px;
  top: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.popupCloseButton span{
  color: #ff3636;
  font-size: 32px;
}

.error{
  width: 100%;
  color: #ff3636;
  font-size: 12px;
}