.projectsMain{
    width: 100%;
}

.projectArt{
    width: 80%;
    position: absolute;
    left: 0;
    height: 100%;
    background-color: #e6f8ee;
    border-radius: 10px 10px 10px 250px;
}

.projectsContentHolder{
    width: 100%;
    padding: 50px 30px;
    display: flex;
    align-items: center;
}

.projectsContentTextSide{
    width: 40%;
    margin-right: 20px;
}

.projectHeadThin{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #03c70e;
}

.projectHeadMega{
    font-size: 40px;
    font-weight: 700;
}

.Normalprojecthead{
    font-weight: 300;
}

.Bolderprojecthead{
    font-weight: 700;
}

.ViewprojectsHolder{
    width: 100%;
    margin-top: 10px;
}

.ViewprojectsAction{
    width: 170px;
    border-radius: 0 30px 30px 30px;
    height: 45px;
}

.projectContentImagesSide{
    width: calc(60% - 20px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
}

.projectImageOneContHolder{
    width: calc(50% - 30px);
    margin-bottom: 15px;
    margin-right: 15px;
    aspect-ratio: 3/2;
    border-radius: 10px;
}

.projectImageTwoContHolder{
    width: calc(50% - 30px);
    margin-bottom: 15px;
    margin-right: 15px;
    aspect-ratio: 3/2;
    border-radius: 20px;
}
.projectImageThreeContHolder{
    width: calc(50% - 30px);
    margin-bottom: 15px;
    margin-right: 15px;
    aspect-ratio: 3/2;
    border-radius: 10px;
}

.projectImagefourContHolder{
    width: calc(50% - 30px);
    margin-bottom: 15px;
    margin-right: 15px;
    aspect-ratio: 3/2;
    border-radius: 10px;
}

.projectImageName{
    background-color: #fff;
    width: fit-content;
    padding: 7px 14px;
    border-radius: 20px;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

@media screen and (max-width: 600px) {
    .projectsContentHolder{
        width: 100%;
        flex-wrap: wrap;
        padding: 30px 10px;
    }

    .projectsContentTextSide{
        width: 100%;
        margin-right: 0;
        margin-bottom: 50px;
    }

    .projectContentImagesSide{
        width: 100%;
    }
}