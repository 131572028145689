.ServiceBoxHomeMain{
    width: 300px;
    padding: 20px 13px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    margin-right: 10px;
}

.ServiceBoxHomeIcon{
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #def3e1;
    border-radius: 5px;
    margin-bottom: 15px;
}

.ServiceBoxHomeHeadHolder{
    margin-bottom: 10px;
}

.ServiceBoxHomeHeadHolder h4{
    font-weight: 600;
}
.ServiceBoxHomeDescription{
    font-size: 13px;
    font-weight: 300;
    text-align: justify;
    padding-bottom: 10px;
}

@media screen and (max-width: 600px ) {
    .ServiceBoxHomeMain {
        margin-bottom: 10px;
    }
}