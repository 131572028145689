.contactUsMain{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #e6f8ee;
    padding: 50px 0;
}

.contactUsContent{
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ContactUsContentLeft{
    width: calc(50% - 20px);
}

.contactUsHead{
    font-size: 20px;
    font-weight: 700;
    color: #5f5f5f;
    margin-bottom: 10px;
}

.contactUsHeadMega{
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 5px;
}

.contactUsPara{
    width: 100%;
    font-weight: 300;
}

.ContactUsContentRight{
    max-width: 500px;
    width: calc(50% - 20px);
}

.ContactUsContentFormHolder{
    width: 100%;
}

.ContactUsContentFormHolder form{
    width: 100%;
    display: block;
}

.inputContactUs{
    width: 100%;
    height: 50px;
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #5f5f5f;
    padding-left: 10px;
    padding-right: 10px;
}

.textAreaContactUs{
    width: 100%;
    display: block;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #5f5f5f;
}

.contactUsSendButton{
    height: 45px;
    width: 140px;
    border-radius: 0 30px 30px 30px;
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .contactUsContent{
        width: 90%;
        flex-wrap: wrap;
    }
    .ContactUsContentLeft{
        width: 100%;
        margin-bottom: 20px;
    }

    .ContactUsContentRight{
        width: 100%;
    }
}