.CoreValueBoxMain{
    width: 350px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
     */
    box-shadow: rgba(116, 119, 121, 0.3) 0px 1px 2px 0px, rgba(99, 101, 102, 0.15) 0px 1px 3px 1px;
    background-color: #fff;
    padding: 25px;
}

.CoreValueBoxNumberHolder{
    background-color: #03c70d50;
    width: 45px;
    border-radius: 7px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
}

.CoreValuesBoxNumber{
    font-size: 24px;
    font-weight: 600;
    color: #03c70d;
}

.CoreValueBoxHead{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.CoreValueBoxDescription{
    font-size: 15px;
    font-weight: 300;
    text-align: justify;
}