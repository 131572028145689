.OurProductsMain{
    width: 100%;
    padding-top: 20px;
}

.OurProductsHeaderContHolder{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.OurProductsHeaderIntro{
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #515151;
    text-transform: uppercase;
}

.OurProductsHeaderMain{
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    max-width: 500px;
}

.OurProductsContentHolder{
    width: 100%;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.ourProductsContentLeft{
    width: calc(50% - 30px);
}

.ourProductsContentRight{
    width: calc(50% - 30px);
}

.ourProductsContentImgHolder{
    width: 100%;
    margin-bottom: 20px;
}

.ourProductsContentImg{
    width: 120%;
}

.ourProductsContentProductName{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.ourProductsContentProductDescription{
    font-weight: 300;
    font-size: 14px;
    text-align: justify;
}

@media screen and (max-width: 600px) {
    .OurProductsContentHolder{
        flex-wrap: wrap;
    }

    .ourProductsContentLeft{
        width: 100%;
        margin-bottom: 20px;
    }

    .ourProductsContentRight{
        width: 100%;
    }
}