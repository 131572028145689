.callActionMain{
    width: 100%;
}

.callActionImgBg{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
}

.shipIconHolderCallActionHome{
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}

.callToActionShipIcon{
    height: 70px;
}

.callActionMessageHomeHolder{
    max-width: 500px;
}

.callActionMessageHomeHolderHalf{
    width: 100%;
    padding: 20px 10px;
    /* background-color: #ff6d01; */
    
    border-radius: 15px;
}

.callActionMessageHome{
    font-weight: 300;
    text-align: center;
    color: aliceblue;
}

.callActionEquariesActionHolder{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.callActionEquariesAction{
    border-radius: 25px;
    height: 45px;
    width: 170px;
    font-weight: 600;
    font-size: 17px;
}

.MajorServicesAbsoluteCallActionHolder{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.leftServiceCallActionContainer{
    height: 210px;
    width: 300px;
    background-color: #A4C2DA;
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
    border-radius: 10px 10px 0 0;
    margin-right: 3px;
    padding: 5px;
    position: relative;
}

.leftServiceCallActionContainer h4{
    width: 79%;
    margin-left: 21%;
    font-weight: 500;
}

.serviceImgHolderCallToAction{
    position: absolute;
    bottom: -20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.serviceImgCallToAction{
    height: 180px;
}
.centerServiceCallActionContainer{
    height: 230px;
    width: 280px;
    background-color: #def3e1;
    border-radius: 10px 10px 0 0;
    margin-right: 3px;
    padding: 5px;
    position: relative;
}

.centerServiceCallActionContainer h4{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.centerImgServiceCallAction{
    height: 150px;
}

.rightServiceCallActionContaine{
    height: 210px;
    width: 300px;
    background-color: #627679;
    clip-path: polygon(0 0, 78% 2%, 100% 100%, 0% 100%);
    border-radius: 10px 10px 0 0;
    padding: 5px;
    position: relative;
}

.rightServiceCallActionContaine h4{
    width: 79%;
    margin-right: 21%;
    font-weight: 500;
}