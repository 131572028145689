.TeamPassportBoxMain{
    width: 250px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.TeamPassportImageHolder{
    width: 100%;
}

.TeamPassportImage{
    width: 100%;
}

.teampassportRankHolder{
    width: 100%;
    padding: 15px;
}

.teampassportName{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.teampassportRank{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #03c70e;
}