.CoreValuesMain{
    width: 100%;
    padding: 30px 0;
    margin-top: 20px;
}

.coreValueBgArtHolder{
    width: 45%;
    height: 100%;
    position: absolute;
    left: -5%;
    top: -1%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0%;
    opacity: 0.55;
}

.coreValueBgArtHolderRight{
    width: 45%;
    height: 100%;
    position: absolute;
    right: -5%;
    top: -1%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0%;
    transform: rotate(180deg); 
    opacity: 0.55;
}

.CoreValuesBoxContentHolder{
    width: 100%;
    padding: 0 5%;
}

.CoreValuesHeaderBox{
    width: 100%;
}

.coreValueMainThinHeader{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #03c70e;
    margin-bottom: 10px;
}

.coreValueMagaBoldHeader{
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.coreValueBigThinHeader{
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 300;
}

.coreValuesBoxesHolder{
    width: 100%;
    padding: 0 6%;
    margin-top: 30px;
}

.coreValueBoxesHolderTopRow{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.CoreValueRowSpacer{
    width: 220px;
}

.coreValueBoxesHolderLowerRow{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

}

@media screen and (max-width: 600px) {
    .coreValuesUnionImg{
        width: 100%;
    }
    .CoreValueRowSpacer{
        width: 30px;
    }

    .coreValueBgArtHolder{
        width: 100%;
    }

    .CoreValuesBoxContentHolder{
        padding: 0 2%;
    }

    .coreValuesBoxesHolder{
        padding: 0 2%;
    }
}