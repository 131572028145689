*{
  box-sizing: border-box;
  outline: none;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
}
html, body {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  color: #1A120B;
}

h1,h2,h3,h4, h5, h6{
  margin: 2px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1A120B;
}

h1, h2{
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
}
a{
  background-color: transparent;
  text-decoration: none;
  color: #000;
}
select{
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 25px;
    background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
    background-repeat: repeat;
    background-repeat: no-repeat;
    background-position: 95% 42%;
}


/* Hide the up and down buttons in number input */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}


button{
  background-color: #03c70e;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s ease-out;
  font-weight: 400;
  color: #fff;
} 

.red-background{
  background-color: #FF3636
 !important;
}
/* #8fc9f1 */
.colorRedText{
  background-color: #FF3636
;
}

button:hover{
  -webkit-transform: scale(1.03,1);
  -moz-transform: scale(1.03,1);
  transform: scale(1.03, 1);
}

body {
  font-weight: 400;
  font-style: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div{
  display: block;
  position: relative;
  height: auto;
  font-weight: 400;
  font-style: normal;
  color: #1A120B;
}

.smallShadow{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.xsmallShadow{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.SpaceTopMargin{
  margin-top: 20px;
}

.dancing-script-normal {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-style: normal;
}