.ServicesMain{
    width: 100%;
    padding-top: 100px;
    padding-left: 5%;
    padding-right: 5%;
}

.ServiceHead{
    width: 100%;
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 40px;
}

.serviceRowHolder{
    width: 100%;
    margin-bottom: 30px;
}

@media screen and (max-width: 500px) {
    .ServicesMain{
        padding-left: 2%;
        padding-right: 2%;
    }
}