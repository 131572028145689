.topNavMain{
    width: 100vw;
    display: flex;
    justify-content: center;
}

.topNavBand{
    background-color: #fff;
    padding: 0px 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.topNavLogoSection{
    width: fit-content;
    display: flex;
    align-items: center;
}

.topNavLogoImg{
    height: 25px;
    display: block;
    margin-right: 10px;
}

.companyNameTopNav{
    font-size: 20px;
    font-weight: 600;
    color: #025006;
    margin-right: 20px;
}

.topNavLinksSections{
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topNavButtonActs{
    background-color: transparent;
    color: black;
    font-size: 15px;
    font-weight: 400;
    width: 120px;
    display: block;
    margin-right: 10px;
    border-bottom: transparent 2px solid;
    border-radius: 0;
    height: 50px;
}

.topNavClientActionSection{
    width: fit-content;
}

.clientLoginActionCallTopNav{
    height: 40px;
    border-radius: 0px 20px 20px 20px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 400;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .companyNameTopNav{
        display: none;
    }
    .topNavButtonActs {
        padding-left: 10px;
        padding-right: 10px;
        width: fit-content;
    }

    .clientLoginActionCallTopNav{
        display: none;
    }
}