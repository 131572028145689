.TeamsectionMain{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TeamSectionTextSide{
    width: 40%;
}

.TeamSectionMemberSide{
    width: 42%;
}

.teamSectionThinHead{
    width: 100%;
    font-size: 20px;
    color: #03c70e;
    font-weight: 700;
    margin-bottom: 10px;
}

.teamSectionBolderHead{
    width: 100%;
    font-size: 40px;
    font-weight: 700;
}

.teamSectionThinMega{
    width: 100%;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 5px;
}

.teamSectionParagraphs{
    font-weight: 300;
    text-align: justify;
}

.contactUsTeamSection{
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.teamSectionInquries{
    border-radius: 0 30px 30px 30px;
    height: 45px;
    width: 150px;
    display: block;
    margin-right: 50px;
}

.teamSectionPhoneNumber{
    display: flex;
    width: fit-content;
}

.IconHolder{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 7px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.phoneContHolder{
    width: fit-content;
}

.phoneClassfication{
    font-size: 13px;
    color: #515151;
    font-weight: 300;
}

.teamPassportsHolder{
    width: 100%;
    height: 800px;
}

.teamPassportPositioner{
    position: absolute;
    
}

.posManager1{
    top: 0;
    right: 0;
}

.posManager2{
    right: 330px;
    top: 30px;
    z-index: 100;
}

.posManager3{
    top: 370px;
    right: 130px;
}

@media screen and (max-width: 600px) {
    .TeamSectionTextSide{
        width: 100%;
    }

    .TeamSectionMemberSide{
        display: none;
    }
}