.ServiceRowMain{
    width: 100%;
}

.ServiceRowHead{
    font-size: 30px;
    text-transform: capitalize;
    color: #033305;
    font-weight: 700;
}

.ServiceImageAndDescrRow{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ServiceImage{
    width: calc(40% - 10px);
    max-width: 450px;
    aspect-ratio: 2/2;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
}

.serviceDescription{
    width: 50%;
}

@media screen and (max-width: 500px) {
    .ServiceImageAndDescrRow{
        width: 100%;
        flex-wrap: wrap;
    }

    .ServiceImage{
        width: 100%;
    }

    .serviceDescription{
        width: 100%;
    }
}