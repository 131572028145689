.footerMain{
    width: 100%;
    padding: 50px 7%;
}

.footerTopSection{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footerTopSecLeft{
    width: 350px;
}

.FooterLogoSec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.footerlogo{
    width: 60px;
}
.footerCompanyName{
    font-weight: 600;
    font-size: 19px;
    margin-left: 10px;
}

.footerTopSectSlogan{
    width: 100%;
    font-weight: 300;
}

.footerTopSecRight{
    width: calc(100% - 400px);
    display: flex;
    justify-content: space-evenly;
}

.footerColumn{
    min-width: 200px;
}

.footerColumnHeader{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
}

.footerColumnText{
    font-weight: 300;
    margin-bottom: 7px;
    font-size: 14px;
}

.newsletterSection{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.newsletterLeftSection{
    width: 45%;
}

.newsLetterHeader{
    font-size: 30px;
    font-weight: 700;
}

.newsletterPara{
    font-weight: 300;
}

.newsletterRightSection{
    width: 500px;
}
.newsletterRightSection form{
    width: 100%;
    display: block;
}

.emailInputAndButtonHolder{
    width: 100%;
}

.newleterEmailInput{
    width: 100%;
    height: 45px;
    border-radius: 30px;
    border: 1px solid #5f5f5f;
    padding-left: 15px;
    padding-right: 100px;
}

.newsleterSubmitButton{
    position: absolute;
    right: 0;
    width: 100px;
    height: 45px;
    border-radius: 30px;
}

.newsLeterLoading{
    position: absolute;
    right: 1px;
    height: 45px;
}

.contactAndCopyRights{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
}

.copyrightsRightSection{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.copyrightsRightSection span{
    font-size: 24px;
    color: #03c70e;
    padding-right: 5px;
}

.copyRightsTextInfo{
    font-size: 16px;
    font-weight: 300;
}

.conctactFooterSection{
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
}

.contractRow{
    margin-right: 50px;
}

.contractRow  h3{
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 16px;
}

.iconHolderContactRow{
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.contactTellFooter{
    font-weight: 300;
    font-size: 14px;
    padding-left: 10px;
    line-height: 18px;
    margin-bottom: 5px;
}

.contactTellFooter span{
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .footerMain{
        padding: 30px 5%;
    }

    .footerTopSection{
        flex-wrap: wrap;
    }

    .footerTopSecLeft{
        min-width: 300;
        max-width: 400px;
        margin-bottom: 20px;
    }

    .footerTopSecRight{
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .footerColumn{
        width: 170px;
        margin-bottom: 15px;
    }

    .newsletterSection{
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .newsletterLeftSection{
        width: 100%;
        margin-bottom: 15px;
    }

    .conctactFooterSection{
        margin-bottom: 15px;
    }
}