#error-page{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.OppsAndSorryholderErrorPage{
    width: 100%;
}

.OppsWordErrorPage{
    width: 100%;
    text-align: center;
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 15px;
}

.SorryWordsErrorPage{
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.explainfromRouter{
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
}

.gotoHomePageErrorPage{
    height: 40px;
    width: 150px;
    border-radius: 20px;
}