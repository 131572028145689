.AboutUsMain{
    width: 100%;
    padding-top: 100px;
    padding-left: 5%;
    padding-right: 5%;
}

.aboutUsTextOneHolder{
    width: 50%;
    min-width: 320px;
    padding-top: 50px;
    margin-bottom: 30px;
}

.aboutUsMainHeader{
    font-weight: 700;
    font-size: 45px;
}

.aboutUsTextParagraph{
    font-weight: 300;
    text-align: justify;
}

.missionTextSection{
    width: 50%;
    min-width: 320px;
    margin-bottom: 30px;
    margin-left: auto;
}

.missionParagraph{
    font-weight: 300;
    text-align: justify;
}

.whyChooseUs{
    width: 50%;
    min-width: 320px;
    margin-bottom: 80px;
}

.whyChooseUsPara{
    font-weight: 300;
    text-align: justify;
}