.InquiryMainPopUp{
    width: 85%;
    max-width: 450px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inquiryLogo{
    width: 50px;
    margin-bottom: 15px;
}

.InquiryMainPopUp h3{
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 10px;
}